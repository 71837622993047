.modal {
    position: fixed;
    left: 0;
    top: 0;
    
    width: 100vw;
    height: 100vh;

    background: rgba(0,0,0,.4);
    z-index: 1000;

    display: none;
    justify-content: center;
    align-items: center;
}

.modal_show {
    display: flex;
}

.modal-content {
    padding: 24px;

    background: #232530;
    border: 1px solid #2F343E;

    border-radius: 10px;

    position: relative;

    min-width: 480px;
    max-width: 550px;
}

.modal-close {
    position: absolute;
    right: 30px;
    top: 24px;
    cursor: pointer;
}

.modal_show {
    
}


@media screen and (max-width: 500px) {
    .modal-content {
        width: 100%;
        height: 100%;
        border-radius: 0px;

        min-width: unset;
    }
}