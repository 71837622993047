.claimBlocked {
    width: 100%;

    background: #1F1F1F;
    border: 1px solid #4F4F4F;
    border-radius: 12px;

    padding: 28px 0px;

    display: flex;
    justify-content: center;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    color: rgba(255, 255, 255, .6);
}