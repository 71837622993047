.staking {
    width: 100%;
    background: #1F1F1F;
    border: 1px solid #4F4F4F;
    border-radius: 12px;
    overflow: hidden;

    &-apy {
        padding-top: 40px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        & hr {
            width: calc((100% - 160px) / 2);
            border: .5px solid #4F4F4F;
        }

        & div {
            width: 160px;

            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;

            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }

    &-header {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 17px 0;
        background: radial-gradient(77.58% 77.58% at 77.12% 22.42%, #454545 0%, #272727 100%);
        border-bottom: 1px solid #4F4F4F;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        color: #FFFFFF;

        & svg {
            margin-left: 8px;
        }
    }

    &-info {
        padding: 32px;
        display: flex;
        justify-content: space-between;

        & > div {
            width: 50%;
        }
    }
}

.stakingInfo {    
    padding: 8px 24px;
    background: #17171A;
    border: 1px solid #4F4F4F;
    border-radius: 8px;

    &-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: #FFFFFF;

        opacity: 0.6;
    }

    &-value {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        color: #FFFFFF;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 12px 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #4F4F4F;
        }
    }
}

.stakingBanner {
    padding: 30px 49px 0px 49px;

    display: flex;
    align-items: start;

    &-icon {
        margin-right: 23px;
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;

        color: #FFFFFF;
    }

    &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* White */

        color: #FFFFFF;
    }
}

.stakingProgress {
    position: relative;

    width: 100%;
    padding: 55px 63px 55px 63px;
    border-bottom: 1px solid #4F4F4F;

    &-months {
        display: flex;

        &-item {
            position: absolute;

            top: -7px;

            width: 4px;
            height: 18px;

            background: #4F4F4F;
            border-radius: 10px;

            display: flex;
            justify-content: center;

            &::before {
                content: attr(data-content);

                position: absolute;
                top: -22px;

                color: #fff;

                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;

                color: #EAEAEA;
            }

            &_checked {
                background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
            }
            
            &_30 {
                left: 30%;
            }
            &_60 {
                left: 60%;
            }
            &_80 {
                left: 80%;
            }

            &_100 {
                left: calc(100% - 4px);
            }
        }
    }

    &-percents {
        display: flex;

        &-item {
            position: absolute;
            bottom: -5px;
            display: flex;
            justify-content: center;

            &::before {
                content: attr(data-content);
                width: max-content;

                position: absolute;
                
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;

                color: #4F4F4F;
            }

            &_checked {
                &::before {
                    color: #FFFFFF;
                    opacity: 1;
                }
            }

            &_15 {
                left: 15%;
            }
            &_45 {
                left: 45%;
            }
            &_80 {
                left: 80%;
            }
        }
    }

    &-line {
        position: relative;
        width: 100%;
        height: 4px;
        background-color: #4f4f4f;
        border-radius: 4px;
    }

    &-filled {
        position: relative;
        
        height: 100%;
        border-radius: 4px;

        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);

        &-dot {
            position: absolute;
            right: -8px;
            top: -6px;
            
            content: "";

            width: 16px;
            height: 16px;
            border-radius: 50%;

            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);

            &::before {
                display: block;

                width: 100%;
                height: 100%;

                border-radius: 50%;

                content: "";

                background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
                filter: blur(5px);

                animation: 4s ease-in-out infinite;
                animation-name: progressDot;
                z-index: -1;
            }
        }
    }
}

@keyframes progressDot {
    0% {
        filter: blur(5px)
    }
    50% {
        filter: blur(10px)
    }
    100% {
        filter: blur(5px)
    }
}

.stakingAmount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-amount {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-action {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .button {
            width: 300px;
        }
    }

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;

        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        margin-bottom: 5px;
    }

    &-description {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;
    }

    &-label {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        color: #FFFFFF;

        opacity: 0.6;
        
        margin-bottom: 8px;
    }
}