.header {
    min-height: 118px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding: 24px 0px;
    padding-bottom: 40px;


    &-account {
        display: flex;
        align-items: center;

        & .balance-border {
            margin-right: 24px;
        }
    }
}