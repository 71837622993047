.claimSuccessModal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 16px 0px;
}

.claimSuccessModal-icon {
    margin-bottom: 16px;
}

.claimSuccessModal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #FFFFFF;
}