
.chooseBlockchainModal-wallets {
    display: flex;
    flex-direction: column;

    align-items: center;

    color: #fff;
}

.chooseBlockchainModal-wallet:not(:last-child) {
    margin-bottom: 16px;
}

.chooseBlockchainModal-wallet {
    width: 100%;

    display: flex;
    align-items: center;

    padding: 20px 24px;

    border: 1px solid #707070;
    border-radius: 8px;

    cursor: pointer;
}

.chooseBlockchainModal-wallet-icon {
    margin-right: 16px;
    height: 32px;
    width: 32px;
}

.chooseBlockchainModal-wallet-icon svg {
    width: 32px;
    height: 32px;
}

.chooseBlockchainModal-wallet-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;
}

.chooseBlockchainModal-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: #FFFFFF;

    margin-bottom: 24px;
}