
.mobileModal {
    width: 100%;
    height: calc(100vh - 200px);
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;

    &-background {
        position: absolute;
        z-index: -1;

        width: 60%;
        height: 302px;

        border-radius: 40%;

        background: radial-gradient(73.51% 78.22% at 82.14% 16.94%, rgba(0, 209, 255, 0.5) 19.79%, rgba(0, 159, 255, 0.5) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        filter: blur(150px);
        animation: 5s ease-in-out infinite;
        animation-name: background;
    }

    &-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;

        color: #FFFFFF;

        text-align: center;
    }
}