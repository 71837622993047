.noAllocation {
    height: calc(100% - 200px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &-background {
        position: absolute;
        z-index: -1;

        width: 60%;
        height: 302px;

        border-radius: 40%;

        background: radial-gradient(73.51% 78.22% at 82.14% 16.94%, rgba(0, 209, 255, 0.5) 19.79%, rgba(0, 159, 255, 0.5) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        filter: blur(150px);
        animation: 5s ease-in-out infinite;
        animation-name: background;
    }

    &-title {
        color: #fff;

        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;

        margin-bottom: 8px;
    }

    &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;

        color: #FFFFFF;

        margin-bottom: 32px;
    }

    &-button {
        cursor: pointer;
        width: 227px;
        max-width: 100%;

        padding: 14px 0;

        display: flex;
        justify-content: center;

        background: #FFFFFF;
        border-radius: 10px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #17171A;
    }
}

@keyframes background {
    0% {
        filter: blur(150px);
    }

    50% {
        filter: blur(70px);
    }

    100% {
        filter: blur(150px);
    }
}