.claim {
    background: radial-gradient(77.58% 77.58% at 77.12% 22.42%, #454545 0%, #272727 100%);
    border: 1px solid #4F4F4F;
    border-radius: 12px;

    padding: 24px;

    & .button:disabled {
        font-size: 14px;
    }

    & .input {
        margin-bottom: 24px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 8px;
    }

    &-label {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        color: #FFFFFF;
    }

    &-balance {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;
    }
}