.checkbox {
    min-width: 22px;
    height: 22px;
    background-color: #fff;
    border: 2px solid #FFFFFF;
    border-radius: 4px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        width: 10px;
    }
}