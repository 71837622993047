
.nextUnlock {
    background: #1F1F1F;
    border: 1px solid #4F4F4F;
    border-radius: 12px;
    overflow: hidden;

    &-header {
        height: 64px;

        padding: 17px 0px 18px 0px;
        background: radial-gradient(77.58% 77.58% at 77.12% 22.42%, #454545 0%, #272727 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border-bottom: 1px solid #4F4F4F;
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;

        color: #FFFFFF;
    }

    &-content {
        height: calc(100% - 64px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }


    &-count {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;

        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        margin-bottom: 5px;
    }

    &-remained {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;
    }
}