
.hover {
    position: relative;
    cursor: pointer;
    height: 24px;
    
    &-content {
        min-width: 250px;
        display: none;
        cursor: default;
        position: absolute;
        bottom: 40px;

        padding: 5px 10px;
        z-index: 1000;
        
        background: rgba(0, 0, 0, 1);
        border-radius: 10px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #FFFFFF;

        & a {
            color: #fff;
        }

        &_show {
            display: block;
        }

        &_orient {
            &_bottom {
                bottom: unset;
                top: 40px;

                &::before {
                    bottom: unset!important;
                    top: -8px;
                    transform: rotate(180deg);
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 12px;
            bottom: -7px;
            border-style: solid;
            border-width: 8px 8px 0px 8px;
            border-color: rgba(0, 0, 0, 1) transparent transparent transparent;

        }
    }
}