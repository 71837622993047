.withdrawStakingModal {
    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;

        color: #FFFFFF;

        margin-bottom: 24px;
    }

    &-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;

        margin-bottom: 24px;
    }

    &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: #FFFFFF;
    }

    &-amount {
        display: flex;

        margin-bottom: 24px;

        & .withdrawStakingModal-item:first-child {
            margin-right: 95px;
        }
    }

    &-value {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        color: #FFFFFF;

        &_blue {
            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

        }
    }

    &-terms {
        margin-bottom: 32px;
    }

    &-check {
        cursor: pointer;
        
        display: flex;
        align-items: center;

        &:first-child {
            margin-bottom: 9px;
        }

        & .checkbox {
            margin-right: 12px;
        }

        & span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            color: #FFFFFF;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;
        
        & .button {
            &:first-child {
                margin-right: 8px;
            }

            &:last-child {
                margin-left: 8px;
            }
        }
    }
}