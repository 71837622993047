.yourAllocation {
    width: 100%;

    &-title {
        margin-bottom: 20px;

        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;

        color: #FFFFFF;
    }

    &-content {
        width: 100%;

        display: flex;

        background: #1F1F1F;
        border: 1px solid #4F4F4F;
        border-radius: 12px;
    }

    &-item {
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 22px 0px;

        &:first-child {
            border-right: 1px solid #4F4F4F;
        }

        &:last-child {
            border-left: 1px solid #4F4F4F;
        }
    }

    &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;

        opacity: 0.6;
    }

    &-value {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;

        color: #FFFFFF;
    }
}