body {
  background: #1C1B1D;
}

.app {
  width: 100%;
  max-width: 1240px;
  height: 100vh;
  margin: 0 auto;

  &-box {
    width: 100%;
    height: calc(100% - 40px - 118px);

    background: #282828;
    border: 1px solid #4F4F4F;
    border-radius: 12px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
  }

  &-navigation {
    width: 200px;
    height: 100%;
  }

  &-content {
    width: calc(100% - 200px);
    height: 100%;
    overflow: auto;

    padding: 0px 40px;

    scrollbar-width: thin;
    scrollbar-color: #4F4F4F;
    
    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background: #282828;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4F4F4F;
      border-radius: 5px;
    }

  }
}

.ledger-ck-modal #ModalWrapper {
  z-index: 1000;
}