.allocation {
    & .tgeTimer {
        padding: 32px 0px;
        border-bottom: 1px solid #4F4F4F;
    }

    &-row {
        width: 100%;

        display: flex;
        justify-content: space-between;

        margin-bottom: 40px;

        & .nextUnlock, .claim {
            width: calc(50% - 8px);
        }
    }
}