.allocationSchedule {
    width: 100%;

    &-item {
        &-check {
            margin-right: 12px;
            display: none;
        }

        &_expired {
            & td:last-child {
                display: flex;
                align-items: center;
                justify-content: space-between;

                & svg {
                    display: block;
                }
            }

            & span {
                opacity: .6;
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;

        margin-bottom: 20px;

        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;

        color: #FFFFFF;

        & svg {
            margin-left: 8px;
        }
    }

    &-table {
        width: 100%;

        background: #1F1F1F;
        border: 1px solid #4F4F4F;
        border-radius: 12px;

        padding: 24px 32px;
    }

    & table {
        width: 100%;
        border-collapse: collapse;
        
        & thead {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            text-align: left;
            text-transform: uppercase;

            color: #FFFFFF;

            & th {
                padding-bottom: 16px;
                border-bottom: 1px solid #4F4F4F;
            }
        }

        & tbody {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;

            color: #FFFFFF;

            & td {
                padding: 20px 0px;
                border-bottom: 1px solid #4F4F4F;
            }
        }
    }
}