.balance {
    height: 52px;
    background: #17171A;
    border-radius: 10px;
    padding: 4px 13px;
    padding-right: 50px;

    &-border {
        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        border-radius: 10px;

        padding: 1px;
    }

    &-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: #FFFFFF;
    }

    &-value {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #14C7FF;
    }
}