.navigation {
    width: 100%;
    height: 100%;
    background: radial-gradient(77.58% 77.58% at 77.12% 22.42%, #454545 0%, #272727 100%);

    &-item {
        cursor: pointer;
        
        width: 100%;
        padding: 16px 0px;
        padding-left: 32px;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        
        color: #FFFFFF;
        background: #4F4F4F;

        border-bottom: 1px solid #000000;


        &_selected {
            color: #17171A;
            background: #EAEAEA;
        }
    }
}