.button {
    width: 100%;

    cursor: pointer;

    border: none;
    outline: none;
    
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Poppins";

    &_view {
        &_default {
            font-style: normal;
            font-weight: 500;
            
            color: #17171A;

            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        }

        &_bordered {
            font-style: normal;
            font-weight: 500;

            background-color: unset;
            border: 1px solid #FFFFFF;

            color: #fff;
        }

        &_dangerous {
            font-style: normal;
            font-weight: 500;
            
            background: #E94C4C;
            color: #fff;
        }

        &_disabled {
            font-style: normal;
            font-weight: 500;
            
            color: #17171A;

            background: #FFFFFF;
        }

        &_icon {
            width: 32px!important;
            height: 32px!important;
            background: #E8E8E8;
            border-radius: 8px;

            &:hover {
                background: hsla(0,0%,91%,.6);
            }
        }
    }

    &_size {
        &_m {
            font-size: 18px;
            line-height: 27px;
            padding: 18.5px 0;
            border-radius: 10px;
        }

        &_s {
            font-size: 14px;
            line-height: 21px;
            padding: 11.5px 0;
            border-radius: 8px;
        }
    }

    &:disabled {
        background: #EAEAEA;
        color: #949494;
        cursor: unset;
    }
}