.tooltip {
    padding: 6px 12px 5px 12px;

    background: #EAEAEA;
    border-radius: 10px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    text-align: center;

    color: #1E1E1E;

    cursor: pointer;

    position: relative;
}

.tooltip-dropdown {
    position: absolute;
    display: none;

    bottom: calc(-100% - 7px);
    left: -90%;

    width: 150px;
    
    background: #EAEAEA;
    border-radius: 4px;

    padding: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;

    color: #17171A;
}

.tooltip-dropdown::before {
    content: "";

    position: absolute;
    top: -3.5px;
    left: 50%;

    width: 7px;
    height: 7px;
    background: #EAEAEA;
    border-radius: 0px 1px 0px 0px;
    transform: rotate(-45deg);
}

.tooltip_selected {
    background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
}

.tooltip:not(.tooltip_selected):hover {
    background: #DADADA;
}

.tooltip:hover .tooltip-dropdown {
    display: block;
}