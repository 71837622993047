body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-loading-skeleton {
  --base-color: #454545;
  --highlight-color: #272727;
  background-color: var(--base-color);
}

.react-loading-skeleton::after {
  background-image: linear-gradient(90deg, #454545, #272727, #454545);
}

.grecaptcha-badge {
  display: none;
}